import Navbar from '../Navbar/Navbar';
import './Header.css'; // You can keep this for other styles if needed

const Header = () => {
  const homepage = 'https://yourwebsite.com'; // Your homepage URL
  const titleImage = '/fnbgl.png'; // Path to your image in the public folder

  return (
    <header className='header center font-bold'>
      <h3>
        {homepage ? (
          <a href={homepage} className='link'>
            <img 
              src={titleImage} 
              alt="Website Title" 
              className='w-20 md:w-32 mx-auto'  // Adjust width for different screen sizes
            />
          </a>
        ) : (
          <img 
            src={titleImage} 
            alt="Website Title" 
            className='w-20 md:w-24 mx-auto'  // Adjust width for different screen sizes
          />
        )}
      </h3>
      <Navbar />
    </header>
  );
}

export default Header;
